import React from "react"
import styled from "styled-components"
import {device, size} from '../utils/media-query.js'

const Row = styled.div`
  display: flex;
  padding-bottom: 1.45rem;

  @media ${device.mobileS} {
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
  }
  @media ${device.laptop} {
    flex-direction: row;
  }
`

export default Row;