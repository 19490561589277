import React from "react"
import styled from "styled-components"
import {device, size} from '../utils/media-query.js'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* default 0 */
  flex-basis: 100%;
  padding: ${props => props.padding ? props.padding : '1.25rem'};
  
`

export default Column;