    import React, {useState} from "react"
    import styled from "styled-components"
    import {H3} from "./Text"
    import {FormButton} from "./FormButton"
    import {color, font} from "../utils/media-query"
    import Row from "./Row"
    import Col from "./Column"
    import Recaptcha from "react-google-recaptcha";
    import { navigateTo } from "gatsby-link";
    function encode(data) {
        return Object.keys(data)
          .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
          .join("&");
      }
    const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY || "6LfHt-EUAAAAALu-2tZfhry9PtJI8T0taLdsojEu";
    const ApplyNow = () => {
        const [name, setName] = useState("")
        const [email, setEmail] = useState("")
        const [phone, setPhone] = useState("")
        const [captcha, setCaptcha] = useState("")
        const [message, setMessage] = useState("")


        const [error, setError] = useState("")
        const [success, setSuccess] = useState(false)

  
        
        const handleRecaptcha = value => {
            setCaptcha({ "g-recaptcha-response": value });
          };
        
        const validate = () => {
            let error = "Please correct the following issues: "
            if(!name){
                error += "\n Please add your name."
            }
            if(!email){
                error += "\n Please add your email."
            }
            if(!phone){
                error += "\n Please add your phone number."
            }
            if(!captcha){
                error += "\n Please complete the captcha."
            }
            if(error != "Please correct the following issues: ") {
                setError(error);
                return false;
            };
            return true;
        }
        const handleSubmit = e => {
            e.preventDefault();
            if(!validate()){
                return;
            }
            
            const form = e.target;
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({
                "form-name": form.getAttribute("name"),
                name,
                email,
                phone,
                message,
                ...captcha
              })
            })
              .then(() => setSuccess(true))
              .catch(error => {
                  setError(error)
                  setSuccess(false);
              });
          };

        return(
            <Wrapper>
                {!success &&
                        <>
                    <Row style={{ margin: 0, padding: 0}}>
                        <Col>
                            <H3 style={{color: '#fff'}}>Are you a missionary in need of transportation?</H3>
                            <p style={{color: '#fff'}}>We want to help. Tell us about your organization, and the transportation issues that you're facing.</p>
                        </Col>
                    </Row>
                    <Form name="contact" method="post" netlify-honeypot="bot-field" data-netlify="true" data-netlify-recaptcha="true" action="/" onSubmit={handleSubmit}>
                    {error &&
                        <Row style={{margin: 0, padding: 0}}>
                            <Col>
                                <Error>{error}</Error>
                            </Col>    
                        </Row>
                    }
                <input type="hidden" name="bot-field" />
                    <Row style={{margin: 0, padding: 0}}>    
                        <Col>
                            <label>
                            Name
                            </label>
                            <input type="text" name="name" id="name" onChange={e => setName(e.target.value)}/>
                        </Col>
                        <Col>
                            <label>
                                Email
                            </label>
                            <input type="email" name="email" id="email" onChange={e => setEmail(e.target.value)}/>
                        </Col>
                        <Col>
                            <label>
                            Phone Number
                            </label>
                            <input type="text" name="phone" id="subject" onChange={e => setPhone(e.target.value)}/>
                        </Col>
                    </Row>
                    <Row style={{margin: 0, padding: 0}}>
                        <Col>
                            <div className="form-col">
                                <label>
                                    Tell us about your organization:
                                </label>
                                <textarea name="message" id="message" rows="5" onChange={e => setMessage(e.target.value)}/>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{margin: 0, padding: 0}}>
                        <Col style={{marginTop: 0, paddingTop: 0}}>
                            <Recaptcha
                                sitekey={RECAPTCHA_KEY}
                                onChange={handleRecaptcha}
                                />                            
                            <FormButton type="submit">Send</FormButton>
                        </Col>
                    </Row>
            </Form>
                </>
                }
                {success && 
                    <Row style={{margin: 0, padding: 0}}>
                        <Col>
                            <Success>
                                Thanks for your submission! We'll be in touch soon!
                            </Success>
                        </Col>
                    </Row>
                }            
            </Wrapper>
        )
    }

    export default ApplyNow

    const Success = styled.div`
        background: #fff;
        padding: 20px;
        border-radius: 5px;
        font-family: ${font.main}
    `
    const Error = styled.p`
        color: red;
        font-weight: bold;
    `
    const Form = styled.form`
        display: flex;
        flex-direction: column;
        color: white;
        .form-col {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        .form-row {
            display: flex;
        }
        label {
            font-family: ${font.main}
        }
        input[type=text], input[type=email],textarea {
            background:${color.slate};
            border: rgba(0,0,0,0) solid 1px;
            transition: ease-in-out, width .35s ease-in-out;
            color: white;
            border-radius: 3px;


        }
        input:focus,textarea:focus {
            border: none ;
            border: orange solid 1px;
            outline: none;
        }
    `
const Wrapper = styled.div`
        border: 2px solid ${color.slate};
        border-radius: 5px;
        max-width: 800px;

    `