import React from "react"
import styled from "styled-components"
import {color, font} from "../utils/media-query"

export const FormButton = styled.button`
 background: ${color.orange};
 font-family: ${font.main};
 border: none;
 color: #fff;
 width: fit-content;
 padding: 10px 20px;
 margin-top: 25px;
 border-radius: 3px;
 transition: all ease-in-out 0.3s;
 &:hover {
     cursor: pointer;
     background: orange;
     transform: scale(1.1)
 }
`
