  import React from "react"
import styled from "styled-components"
import Container from "./Container"
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Link } from "gatsby"
import Logo from "./Logo";
import {device, size, color} from '../utils/media-query.js'
import {motion} from "framer-motion"

const Hero = () => (
          <StyledBackgroundSection>
           
          </StyledBackgroundSection>
  )
  // const NavItem = posed.p({
  //   slideIn: { x: '0%' },
  //   slideOut: { x: '100%' }
  // })
  const HeroWrapper = styled.section`
    background: #795548;
    padding: 50px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: calc(100vh - 67.5px);
    text-transform: uppercase;
  `
  const HeroText = styled.p`
  color: white;
  text-align: left;
  font-family: 'Bree Serif', serif;
  background: ${color.green};
  padding: 2rem;
  @media ${device.mobileS} {
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.4rem;

  }
  @media ${device.tablet} {
    max-width: 350px;
    font-size: 1.2rem;
    line-height: 1.4rem;

  }
  @media ${device.laptop} {
    max-width: 550px;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  @media ${device.laptopL} {
    max-width: 750px;
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  `
  
  const HeroButton = styled.a`
    background: ${color.brown};
    border: none;
    font-family: 'Bree Serif', serif;

    color: #fff;
    border-radius: 1px;
    padding: 1rem 1.5rem;
    text-transform: uppercase;
    cursor: pointer;
    :hover {
        background: #fff;
        color: #000;
    }
    @media ${device.tablet} {
      font-size: .8rem;
    }
    @media ${device.laptop} {
      font-size: 1rem;
    }
    @media ${device.laptopL} {
      font-size: 1.2rem;
    }
  `


const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "smit-shakya-Ul0-jcZyrHU-unsplash-compressed.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid

      const container = {
        show: {
          x: 0,
          transition: {
            staggerChildren: 0.2,
            staggerDirection: -1

          }
        },
        hidden: { x: 1000 },
      }

      const text = {
        hidden: { x: 1000 },
        show: { x: 0 }
      }

      const spring = {
        type: "spring",
        damping: 10,
        stiffness: 20
      }
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
          style={{padding: 50}}
        >
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <TextWrapper>

            <motion.div variants={container} initial="hidden" animate="show">

              <motion.div variants={text} transition={{spring,  duration: 1}}>
                <HeroText>
                  Providing OHV transportation to front line Christian advancement in some of the most remote and rough terrain on the planet.
                </HeroText>
              </motion.div>
                {/* <motion.div variants={text} transition={{spring, duration: 1}}>
                  <HeroButton href="#donate">
                  Donate
                  </HeroButton>
                </motion.div> */}
            </motion.div>
          </TextWrapper>
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
    width: 100%;
    background-position: center center;
    background-repeat: repeat-y;
    background-size: cover;
    padding: 50px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 88px);
    text-transform: uppercase;
    align-items: center;

`
const TextWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 88px);
    text-transform: uppercase;
`
const LogoWrapper = styled.div`
    display: flex;
    align-content: center;
`
  
  export default Hero;