import React from "react"
import {useStaticQuery, Link, graphql} from "gatsby";
import Img from "gatsby-image"

const Logo = () => {
    const data = useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "Banner-1.png" }) {
          childImageSharp {
              fluid {
                  ...GatsbyImageSharpFluid
              }
          }
        }
      }
    `)
    return(    
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
          maxWidth: '100%',
          width: '60vw',
          minWidth: '400px'
        }}
      >
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="Gatsby Docs are awesome"
        />
      </Link>
    )
  }

  export default Logo;