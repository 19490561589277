import React, {useState} from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import {device, size} from '../utils/media-query.js'
import Hero from "../components/Hero"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Container from "../components/Container"
import Column from "../components/Column.js"
import Row from "../components/Row"
import Col from "../components/Column"

import Board from "../components/Board"
import {motion, useViewportScroll} from "framer-motion"
import {H3 ,H2} from "../components/Text"
import BackgroundImage from 'gatsby-background-image'
import {color} from '../utils/media-query'
import MyanmarImage from '../components/myanmarImage'
import HowWeHelpImage from '../components/howWeHelp'
import ApplyNow from '../components/ApplyNow'
const IndexPage = () => {

  const [test, setTest] = useState(true);
  const [test2, setTest2] = useState("test");
  return( 
  <Layout>
    <SEO title="Transportation for missions across the globe." description="Motomission is a non-profit organization geared towards providing transportation to missionaries working in some of the most remote terrain on the planet." />
    <Hero />
    <Container>
      <Facts />
    </Container>
    <BackgroundSection />

    <Board />   
    <Container fullwidth style={{background: "#000"}} id="donate">
      <Row>
        <Col>
          <DonationWrapper>
            <div>
              <H3 style={{color: "white"}}>Your donation makes a difference.</H3>
              <p style={{color: "white"}}>Every dollar that you donate goes towards transportation for a missionary in need.</p>
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="Y92T9VLQV9CEY" />
              <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
              <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
              </form>
            </div>
          </DonationWrapper>
        </Col>
        <Col>
          <ApplyNow />
        </Col>
      </Row>
    </Container>
  </Layout>
)
  }

export default IndexPage


const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "wantto-create-BcnjcRmtQRI-unsplash.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid

      const container = {
        show: {
          x: 0,
          transition: {
            staggerChildren: 0.2,
            staggerDirection: -1

          }
        },
        hidden: { x: 1000 },
      }

      const text = {
        hidden: { x: 1000 },
        show: { x: 0 }
      }

      const spring = {
        type: "spring",
        damping: 10,
        stiffness: 20
      }
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
          style={{padding: 50}}
        >
          <motion.div variants={container} initial="hidden" animate="show">

            <motion.div variants={text} transition={{spring,  duration: 1}}>
              <Container>

                <Row style={{paddingBottom: 0}} flexDirection="column-reverse">
                  <Column padding="0" style={{background:  "rgba(227, 227, 227, 0.85)"}}>
                    <Content>
                      <H3>How We Work</H3>
                      <p>
                        Funds come in to MotoMission and are collected throughout the year for the placement or purchase of motocycles or 4-wheelers to various missionaries/organizations the following year.  MotoMission has a 3 person Board of Directors that meets every January to assess the amount of giving and donations and matches 100% of those amounts with needs placed for bikes or 4-wheelers.  Those bikes or 4-wheelers will either be purchased in the US and shipped to the missionaries, or, if more reasonable and expeditious, funds will be sent directly to the missionary to acquire an “indiginous” motorcycle or 4-wheeler.
                      </p>
                    </Content>
                  </Column>
                  <Column padding="0">
                    <HowWeHelpImage style={{height: "100%"}} />
                  </Column>
                </Row>
                <Row>
                  <Column padding="0">
                  <MyanmarImage />

                  </Column>
                  <Column padding="0" style={{background: "rgba(227, 227, 227, 0.85)"}}>
                    <Content>
                      <H3>Where We Work.</H3>
                      <ul>
                        <li>Guatemala</li>
                        <li>Myanmar</li>
                      </ul>
                    </Content>
                  </Column>
                </Row>
              </Container>
          </motion.div>
          </motion.div>
        </BackgroundImage>
      )
    }}
  />
)

const DonationWrapper = styled.div`
  max-width:500px;
  height: 500px;
  margin: auto;
  display: flex;
  align-items: center;
`;
const Facts = () => {
  const { scrollYProgress } = useViewportScroll()
  return(

  <FactsWrapper>
    <Row>
      <Column>
        <motion.div animate={{x: scrollYProgress}} >
        <H2 style={{textAlign: "center", color: color.green}}>9/10 missionaries are in need of a reliable transportation.</H2>
          <p style={{textAlign: "center"}}>
            This effects not only the individual but the economy that they could play a part in.
          </p>
        </motion.div> 
      </Column>
    </Row>
  </FactsWrapper>

)
  }

const FactsWrapper = styled.section`
  display: flex;
  align-items: center;
  height: 500px;
  padding: 1.5rem;

  h2 {
    font-size: 1.5rem;
  }

`
const Content = styled.div`
  padding: 1.25rem;
`