import React from "react"
import styled from "styled-components"
import Container from "./Container"
import Row from "./Row"
import Col from "./Column"
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import {H3} from "../components/Text"

import {device, size} from '../utils/media-query.js'


const Board = () => (
          <Container>
            <H3>Our Board</H3>
            <Row>
                <Col>
                    <BoardMember title="Mark Weaver">
                        Mark Weaver serves as Owner and President of Ascent Commercial Group, LLC and is responsible for all related activity for consulting, brokerage, leasing, and project development of Ascent.  He has over 35 years of experience and background in commercial and development real estate, both in independent brokerage and consulting as well as in corporate real estate asset management.  Mr. Weaver is primarily involved with commercial/land development and investment consulting for various clients in a 5-state area – primarily in Colorado, New Mexico and Texas.  He is a licensed real estate Broker in both Colorado and Texas and holds the prestigious CCIM and ALC designations.
                    </BoardMember>
                </Col>
                <Col>
                    <BoardMember title="John Mark">
                    John Mark is currently employed by Woodland HVAC and Plumbing in Woodland Park, CO, as a “plumbers helper”  He works to travel, as he has already been to 8 countries around the world and recently completed a 3 month solo trek into the Himalayas.  His next adventure will likely take him to the Southern Hemisphere to explore the Andes and the southern most parts of South America.
                    </BoardMember>
                </Col>
            </Row>
            <Row>
                <Col>
                    <BoardMember title="Mary Jean">
                        Mary Jean is a Staff Pharmacist with Kroger Corporation and has served in the pharmaceutical world for over 35 years now.  She hold a degree from the University of Texas at Austin and has post-doctorate studies in Neuropharmacology.  Raised in Texas, she has a love of the outdoors, horses, motorcycles and good southern cooking!
                    </BoardMember>
                </Col>
                <Col></Col>
            </Row>
          </Container>
  )



  


const BoardMember = ({title, children}) => (
    <div>
        <h4>{title}</h4>
        <p>{children}</p>
    </div>
)


  
  export default Board;